import { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { uniqBy } from 'lodash'
import qs from 'qs'
import antdNotification from '@edulastic/common/src/components/Notification'
import * as Fbs from '@edulastic/common/src/Firebase'
import { roleuser } from '@edulastic/constants'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { notification } from '@edulastic/common'
import { getUser, getUserOrgId } from '../../../src/selectors/user'
import { receiveAssignmentClassList } from '../../../src/actions/assignments'
import { destroyNotificationMessage } from '../../../../common/components/Notification'
import { setAssignmentBulkActionStatus } from '../../ducks'
import { getFilterFromSession } from '../../../../common/utils/helpers'
import { canvasSyncFireBaseStatus } from '../../../ManageClass/constants'
import { themeColor } from '@edulastic/colors'
import { styledNotification } from '../../../Reports/common/styled'

const collectionName = 'AssignmentBulkActionEvents'
const bulkSettingsCollectionName = 'BulkAssignmentsSettings'
const canvasDistrictSyncStatusCollection = 'CanvasClassSyncStatus'

const NotificationListener = ({
  user,
  location,
  fetchAssignmentClassList,
  setBulkActionStatus,
  history,
  orgId,
}) => {
  const [notificationIds, setNotificationIds] = useState([])
  let districtId = ''
  let testId = ''
  const { termId = '', grades = [], assignedBy = '' } = getFilterFromSession({
    key: 'assignments_filter',
    userId: user._id,
    districtId: orgId,
  })
  const { testType = '' } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })
  if (testType) {
    const locationArray = location?.pathname?.split('/') || []
    districtId = locationArray[locationArray?.length - 2] || ''
    testId = locationArray[locationArray?.length - 1] || ''
  }
  const userNotifications = Fbs.useFirestoreRealtimeDocuments(
    (db) => db.collection(collectionName).where('userId', '==', `${user?._id}`),
    [user?._id]
  )

  const bulkSettingsUpdateNotifications = Fbs.useFirestoreRealtimeDocuments(
    (db) =>
      db
        .collection(bulkSettingsCollectionName)
        .where('userId', '==', `${user?._id}`)
        .where('processStatus', '==', 'done'),
    [user?._id]
  )
  const canvasDistrictSyncNotifications = Fbs.useFirestoreRealtimeDocuments(
    (db) =>
      db
        .collection(canvasDistrictSyncStatusCollection)
        .where('userId', '==', `${user?._id}`),
    [user?._id]
  )
  const deleteNotificationDocument = (docId, collection) => {
    Fbs.db
      .collection(collection)
      .doc(docId)
      .delete()
      .catch((err) => console.error(err))
  }

  const showUserNotifications = (docs, collection) => {
    uniqBy(docs, '__id').forEach((doc) => {
      const {
        processStatus,
        message,
        statusCode,
        isBulkAction,
        successCount, // how many test has been process successfully
        totalCount, // how many total test should be processed
        status,
        assignmentSettings,
        contentVisibilityFailedTestsDownloadLink,
      } = doc
      if (collection === canvasDistrictSyncStatusCollection) {
        if (
          status === canvasSyncFireBaseStatus.COMPLETED &&
          !notificationIds.includes(doc.__id)
        ) {
          setNotificationIds([...notificationIds, doc.__id])
          notification({
            msg: `${message || 'Canvas district sync completed.'}`,
            type: 'success',
            onClose: () => {
              deleteNotificationDocument(
                doc.__id,
                canvasDistrictSyncStatusCollection
              )
            },
          })
        }
        if (status === canvasSyncFireBaseStatus.FAILED) {
          notification({
            msg: `${message || 'Canvas district sync failed.'}`,
            type: 'error',
            onClose: () => {
              deleteNotificationDocument(
                doc.__id,
                canvasDistrictSyncStatusCollection
              )
            },
          })
        }
      }
      if (
        isBulkAction &&
        status === 'initiated' &&
        processStatus === 'done' &&
        !notificationIds.includes(doc.__id)
      ) {
        let _message = message

        // updating message based upon notification document for bulk update assignment settings
        if (collection === bulkSettingsCollectionName) {
          const {
            allowTeacherRedirect,
            releaseScore,
            endDate,
          } = assignmentSettings
          let label = ''

          if (allowTeacherRedirect !== undefined) {
            label = 'Allow Teachers to Redirect'
          } else if (releaseScore) {
            label = 'Release Score Policy'
          } else if (endDate) {
            label = 'Close date'
          }
          if (successCount === 0) {
            _message = `${label} failed for ${totalCount} assignments. Please try again.`
          } else {
            _message = `${label} updated for ${successCount} out of ${totalCount} assignments.`
          }
        }

        setNotificationIds([...notificationIds, doc.__id])
        let contentVisibilityNotification = ''
        if (
          assignmentSettings?.testContentVisibility &&
          contentVisibilityFailedTestsDownloadLink
        ) {
          const text = (
            <>
              {_message}
              <a
                href={contentVisibilityFailedTestsDownloadLink}
                data-cy="download-csv-notification"
                style={{ color: themeColor, cursor: 'pointer' }}
              >
                {' Download Now '}
              </a>
            </>
          )
          contentVisibilityNotification = {
            type: statusCode === 200 ? 'success' : 'warn',
            msg: text,
            key: doc.__id,
            duration: 10,
          }
        }
        if (
          assignmentSettings?.testContentVisibility &&
          contentVisibilityFailedTestsDownloadLink
        ) {
          styledNotification({
            ...contentVisibilityNotification,
          })
        } else {
          antdNotification({
            type: statusCode === 200 ? 'success' : undefined,
            msg: _message,
            key: doc.__id,
          })
        }

        // if status is initiated and we are displaying, delete the notification document from firebase
        deleteNotificationDocument(doc.__id, collection)
        if (districtId && testId && testType) {
          fetchAssignmentClassList({
            districtId,
            testId,
            testType,
            termId,
            pageNo: 1,
            status: '',
            grades,
            assignedBy,
            filteredGroupIds: [],
            recompute: true,
          })
        }

        // if user at assignments home page and bulk action has been processed successfully
        const isAssignmentsHomePage =
          !districtId &&
          !testId &&
          !testType &&
          location?.pathname?.includes('author/assignments') &&
          statusCode == 200

        if (isAssignmentsHomePage) {
          setTimeout(() => history.push('author/assignments'), 3000)
        }
        setBulkActionStatus(false)
      }
    })
  }

  useEffect(() => {
    if (user && roleuser.DA_SA_ROLE_ARRAY.includes(user.role)) {
      showUserNotifications(userNotifications, collectionName)
    }
  }, [userNotifications])

  useEffect(() => {
    if (user && roleuser.DA_SA_ROLE_ARRAY.includes(user.role)) {
      showUserNotifications(
        bulkSettingsUpdateNotifications,
        bulkSettingsCollectionName
      )
    }
  }, [bulkSettingsUpdateNotifications])
  useEffect(() => {
    if (user && roleuser.DISTRICT_ADMIN.includes(user.role)) {
      showUserNotifications(
        canvasDistrictSyncNotifications,
        canvasDistrictSyncStatusCollection
      )
    }
  }, [canvasDistrictSyncNotifications])

  useEffect(
    () => () => {
      destroyNotificationMessage()
    },
    []
  )

  return null
}

export default compose(
  withRouter,
  connect(
    (state) => ({
      user: getUser(state),
      orgId: getUserOrgId(state),
    }),
    {
      fetchAssignmentClassList: receiveAssignmentClassList,
      setBulkActionStatus: setAssignmentBulkActionStatus,
    }
  )
)(NotificationListener)
